import React, { useState, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import CommonSingleInputField from "./CommonSingleInputField";
import CommonLandingPage from "./CommonLandingPage";
import CommonValidationStatus from "./CommonValidationStatus";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";

/**
 * NOTE: Structured Component
 * @description This Component is a forget passowrd page.
 * @return {*}
 */
const ForgetPassword = () => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [isResetEmailSent, setIsResetEmailSent] = useState(null);
  const [username, setUsername] = useState("");
  const [isUsernameValid, setIsUserNameValid] = useState(true);

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const checkUserName = () => {
    const userNameRegex1 = new RegExp(/^[a-zA-Z0-9]{6,15}$/);
    const userNameRegex2 = new RegExp(/^\d+$/);

    if (
      userNameRegex1.test(username) == false ||
      userNameRegex2.test(username) == true
    ) {
      setIsUserNameValid(false);
      showMessage(
        "Username must be 6 to 15 characters, and alphanumeric or alphabets only."
      );
      return false;
    } else {
      return true;
    }
  };

  const handleClickHandler = async () => {
    setIsUserNameValid(true);

    //Input Fields Validation
    let checkUserNameResponse = await checkUserName();

    if (checkUserNameResponse == true) {
      try {
        let stringifiedData = JSON.stringify({
          clientId: env.REACT_APP_CLIENT_ID,
        });
        //API Call: Sending OTP to registered email-Id for Forget Password.
        let sendOtpResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/generateOtp",
          stringifiedData
        );

        if (sendOtpResponse.data.code == 200) {
          setIsResetEmailSent(true);
        }
      } catch (error) {
        setIsResetEmailSent(false);
      }
    }
  };

  const handleContinueButtonClick = () => {
    history.push({
      pathname: "/forgetPasswordOtpVerification",
      userName: username,
    });
  };

  const handleTryAgainButtonClick = () => {
    history.push("forgetPassword");
  };

  return (
    <CommonLandingPage>
      {isResetEmailSent == null ? (
        <CommonSingleInputField
          title={"Forgot your Password?"}
          description={
            "No worries, we will send an email with instructions to reset your password along with OTP."
          }
          placeholder={"Enter Username here"}
          inputType={"text"}
          buttonState={username == ""}
          buttonPlaceholder={"Continue"}
          inputFieldMember={[
            "Remember your Password? ",
            <b>
              <a href="/login">
                <u>Login</u>
              </a>
            </b>,
          ]}
          handleClickHandler={handleClickHandler}
          handleUsernameChange={handleUsernameChange}
          isUsernameValid={isUsernameValid}
        />
      ) : isResetEmailSent == true ? (
        <CommonValidationStatus
          title={"Password reset details successfully sent"}
          description={
            "We have successfully sent an email with password reset instructions to your email. Please verify the OTP and change your password"
          }
          status={"success"}
          buttonPlaceholder={"Continue"}
          handleButtonClick={handleContinueButtonClick}
          helperTextRoute={"/login"}
          helperText={"Login"}
        />
      ) : (
        <CommonValidationStatus
          title={"Failed to send password reset details"}
          description={"Please try again!!"}
          status={"fail"}
          buttonPlaceholder={"Try Again"}
          handleButtonClick={handleTryAgainButtonClick}
        />
      )}
    </CommonLandingPage>
  );
};

export default withRouter(ForgetPassword);
