import { render } from "react-dom";

// Render these styled components like normal react components.
// They will pass on all props and work
// like normal react components – except they're styled!

import React from "react";
import styled, { keyframes } from "styled-components";

// Define variables for the skeleton colors and dimensions
const backgroundColor = "rgba(23, 23, 23, 0.80)";
const foregroundColor = "#313131";
// const backgroundColor = "#f4f4f4";
// const foregroundColor = "#e0e0e0";
const width = "100%";
const borderRadius = "4px";
const animationDuration = "1.2s";

// Define the keyframe animation for the skeleton loading animation
const skeletonLoading = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

// Define the styled component for the skeleton loading animation
const Skeleton = styled.div`
  background-color: ${backgroundColor};
  position: relative;
  overflow: hidden;
  width: ${width};
  height: ${(props) => (props.height ? props.height : "10vh")};
  border-radius: ${borderRadius};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: ${foregroundColor};
    transform: translateX(-100%);
    animation: ${skeletonLoading} ${animationDuration} infinite ease-in-out;
  }
`;

// Use the Skeleton component to create a skeleton element
const SkeletonLoading = (props) => {
  const { height } = props;
  return (
    <div>
      <Skeleton height={height} />
    </div>
  );
};

export default SkeletonLoading;
