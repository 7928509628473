import { useHistory, withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import React, { useState, useContext } from "react";
import ButtonLandingPage from "../LandingPages/CommonButtonLandingPage";
import CommonLandingPage from "./CommonLandingPage";
import CommonMultiInputField from "./CommonMultiInputField";
import jwtDecode from "jwt-decode";
import { setItem } from "../../storage";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { env } from "../../env";
import { useTour } from '@reactour/tour'

/**
 * NOTE: Structured Component
 * @description This Component is a user login page.
 * @return {*}
 */
const Login = (props) => {
  const { setIsOpen } = useTour();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isUsernameValid, setIsUserNameValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const checkUserName = () => {
    const userNameRegex1 = new RegExp(/^[a-zA-Z0-9]{6,15}$/);
    const userNameRegex2 = new RegExp(/^\d+$/);

    if (
      userNameRegex1.test(username) == false ||
      userNameRegex2.test(username) == true
    ) {
      setIsUserNameValid(false);
      showMessage(
        "Username must be 6 to 15 characters, and alphanumeric or alphabets only."
      );
      return false;
    } else {
      return true;
    }
  };

  const checkPassword = () => {
    const passwordRegex = new RegExp(
      /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9!@#$%^&*_])(.{8,15})$/
    );

    if (passwordRegex.test(password) == false) {
      setIsPasswordValid(false);
      showMessage(
        "Password must have 8 to 15 aplhanumeric characters, one capital letter, special characters allowed are !@#$%^&*_"
      );
      return false;
    } else {
      return true;
    }
  };

  const onClickEvent = async () => {
    let checkPasswordResponse;
    setIsUserNameValid(true);
    setIsPasswordValid(true);
    setIsOpen(true)
    //Input Fields Validation
    let checkUserNameResponse = await checkUserName();

    if (checkUserNameResponse == true) {
      checkPasswordResponse = await checkPassword();
    }

    if (checkUserNameResponse == true && checkPasswordResponse == true) {
      let stringifiedData = JSON.stringify({
        userName: username,
        password: password,
      });

      //API Call: To fetch login Token.
      try {
        let generateLoginTokenResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/generateLoginToken",
          stringifiedData
        );

        if (generateLoginTokenResponse.data.code == 200) {
          let token = generateLoginTokenResponse.data.data.token;

          const { loginId, roles, userName } = jwtDecode(token);

          //Setting up local storage
          setItem("loginId", loginId, "PERSISTENT");
          setItem("userName", userName, "PERSISTENT");
          setItem("roles", roles, "PERSISTENT");
          setItem("token", token, "PERSISTENT");

          history.push("onBoarding");
        }
      } catch (err) {
        showMessage(err.response.data.remarks);
      }
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <CommonLandingPage>
      <CommonMultiInputField
        title={"Continue to Condense X"}
        description={"Please create a new account or Sign in to continue."}
        inputPlaceholder={"Username"}
        inputType={"text"}
        inputPlaceholder1={"Password"}
        inputType1={"password"}
        username={username}
        password={password}
        handleUsernameChange={handleUsernameChange}
        handlePasswordChange={handlePasswordChange}
        isUsernameValid={isUsernameValid}
        isPasswordValid={isPasswordValid}
      />
      <Col xs={12} className="loginButtonStyle">
        <ButtonLandingPage
          disabled={username == "" || password == ""}
          buttonPlaceholder={"Login"}
          linkLable={[
            "Not able to login? ",
            <b>
              <a href="/forgetPassword">
                <u>Forgot Password</u>
              </a>
            </b>,
          ]}
          onClickEvent={onClickEvent}
        />
      </Col>
    </CommonLandingPage>
  );
};

export default withRouter(Login);
