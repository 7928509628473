import React, { useState, useContext, useEffect } from "react";

const SnackbarContext = React.createContext({
  message: "",
  showMessage: () => {},
});

const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const showMessage = (newMessage, type) => {
    setMessage(newMessage);
    setMessageType(type);
  };

  return (
    <SnackbarContext.Provider value={{ message, showMessage, messageType }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, SnackbarContext };
