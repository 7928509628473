import PipeLineIcon from "../../assets/images/Pipelines.svg";
import TemplateIcon from "../../assets/images/Templates.svg";
import ConnectorsIcon from "../../assets/images/Connectors.svg";
import WindowsIcon from "../../assets/images/Windows.svg";
import DocumentationIcon from "../../assets/images/Documentation.svg";
import SupportIcon from "../../assets/images/Support.svg";

export const arrData = [
  // {
  //   id: 1,
  //   name: "Pipelines",
  //   logo: PipeLineIcon,
  //   dropDown: true,
  // },
  // {
  //   id: 2,
  //   name: "Templates",
  //   logo: TemplateIcon,
  //   dropDown: false,
  // },
  {
    id: 3,
    name: "Connectors",
    logo: ConnectorsIcon,
    dropDown: false,
  },
  // {
  //   id: 4,
  //   name: "Windows",
  //   logo: WindowsIcon,
  //   dropDown: true,
  // },
  // {
  //   id: 5,
  //   name: "Documentation",
  //   logo: DocumentationIcon,
  //   dropDown: true,
  // },
  // {
  //   id: 6,
  //   name: "Support",
  //   logo: SupportIcon,
  //   dropDown: true,
  // },
];

export const pipeLineArr = [
  {
    id: 1,
    name: "FMB01",
  },
  {
    id: 2,
    name: "FMB02",
  },
  {
    id: 3,
    name: "FMB03",
  },
  {
    id: 4,
    name: "FMB04",
  },
  {
    id: 5,
    name: "FMB05",
  },
  {
    id: 6,
    name: "FMB06",
  },
  {
    id: 7,
    name: "FMB07",
  },
  {
    id: 8,
    name: "FMB08",
  },
  {
    id: 9,
    name: "FMB09",
  },
  {
    id: 10,
    name: "FMB10",
  },
];

export const windowsArr = [
  {
    id: 1,
    name: "Window01",
  },
  {
    id: 2,
    name: "Window02",
  },
  {
    id: 3,
    name: "Window03",
  },
  {
    id: 4,
    name: "Window04",
  },
  {
    id: 5,
    name: "Window05",
  },
  {
    id: 6,
    name: "Window06",
  },
  {
    id: 7,
    name: "Window07",
  },
  {
    id: 8,
    name: "Window08",
  },
  {
    id: 9,
    name: "Window09",
  },
  {
    id: 10,
    name: "Window10",
  },
];

export const documentArr = [
  {
    id: 1,
    name: "Document01",
  },
  {
    id: 2,
    name: "Document02",
  },
  {
    id: 3,
    name: "Document03",
  },
  {
    id: 4,
    name: "Document04",
  },
  {
    id: 5,
    name: "Document05",
  },
  {
    id: 6,
    name: "Document06",
  },
  {
    id: 7,
    name: "Document07",
  },
  {
    id: 8,
    name: "Document08",
  },
  {
    id: 9,
    name: "Document09",
  },
  {
    id: 10,
    name: "Document10",
  },
];

export const supportArr = [
  {
    id: 1,
    name: "Support01",
  },
  {
    id: 2,
    name: "Support02",
  },
  {
    id: 3,
    name: "Support03",
  },
  {
    id: 4,
    name: "Support04",
  },
  {
    id: 5,
    name: "Support05",
  },
  {
    id: 6,
    name: "Support06",
  },
  {
    id: 7,
    name: "Support07",
  },
  {
    id: 8,
    name: "Support08",
  },
  {
    id: 9,
    name: "Support09",
  },
  {
    id: 10,
    name: "Support10",
  },
];
