import { useContext } from "react";
import { NavbarContext } from "./NavBar";
import {
  pipeLineArr,
  documentArr,
  supportArr,
  windowsArr,
} from "./NavBarUtils";

let data;
const NavBarCommonDropdown = (props) => {
  const { name } = props;

  const { setSelectedDropDownItem } = useContext(NavbarContext);

  switch (name) {
    case "Pipelines":
      data = pipeLineArr;
      break;
    case "Windows":
      data = windowsArr;
      break;
    case "Documentation":
      data = documentArr;
      break;
    case "Support":
      data = supportArr;
      break;
    default:
      // Handle the case when name doesn't match any of the options
      break;
  }

  return (
    <div className="navBarCommonDropDownContainer">
      {data.map((eachData) => {
        return (
          <div
            className="navBarCommonDropDownEachContainer"
            style={{ display: "flex" }}
            onClick={() => setSelectedDropDownItem(eachData.name)}
          >
            <div className="navBarCommonDropDownIcon"></div>
            <p key={eachData.id}>{eachData.name}</p>;
          </div>
        );
      })}
    </div>
  );
};

export default NavBarCommonDropdown;
