import axios from "axios";
import { useEffect, useState } from "react";
import Typography from "../../components/common/_typography";
import { Col, Container, Row } from "reactstrap";
import ProfileCard from "../../components/onBoardingComponents/_profileCard";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import SkeletonLoading from "../../components/common/_skeletonLoading";

const OnboardingWorkSpaceList = (props) => {
  const { reFetchWorkSpace } = props;

  const [workSpaces, setWorkSpaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchWorkSpaces();
  }, [reFetchWorkSpace]);

  const fetchWorkSpaces = async () => {
    setIsLoading(true);
    let fetchWorkSpacesResponse = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace"
    );
    //API Call: Validating OTP and Resetting Password.

    const sortedData = fetchWorkSpacesResponse.data.data.sort(
      (a, b) => b.id - a.id
    );

    setWorkSpaces(sortedData);
    setIsLoading(false);
  };

  const handleClickedWorkSpace = (
    workSpaceId,
    workSpaceName,
    workSpaceIconLink
  ) => {
    //Finding selected workspace
    let selectedWorkSpace = workSpaces.find(
      (eachWorkSpace) => eachWorkSpace.id === workSpaceId
    );

    localStorage.setItem("selectedWorkSpaceName", workSpaceName);
    localStorage.setItem("selectedWorkSpaceId", workSpaceId);
    localStorage.setItem("selectedWorkSpaceIconLink", workSpaceIconLink);

    props.handlingWorkSpace(workSpaceId, selectedWorkSpace.layout);
  };

  return (
    <div className="workspaceCardsStyle">
      {isLoading ? (
        <SkeletonLoading height={"50vh"} />
      ) : (
        <div className="workSpaceGridContainer">
          {workSpaces.length > 0 &&
            workSpaces.map((eachWorkSpace) => {
              return (
                <div>
                  <ProfileCard
                    eachWorkSpace={eachWorkSpace}
                    handleClickedWorkSpace={handleClickedWorkSpace}
                  />
                </div>
              );
            })}
        </div>
      )}

      <br />
    </div>
  );
};
export default OnboardingWorkSpaceList;
