import Typography from "./_typography";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import TickIcon from "../../assets/images/task_alt_white.svg";

const DataCardComponent = styled.div`
  margin: 0px 0px 16px 0px;
  width: 220px;
  height: 238px;
  cursor: ${(props) => (props.disabled == false ? "pointer" : null)};
  background: ${(props) =>
    props.disabled == false && props.isSourceSelected
      ? "rgba(71, 204, 214, 0.16)"
      : "#313131"};
  border: ${(props) =>
    props.disabled == false && props.isSourceSelected
      ? "2px solid #47CCD6"
      : "2px solid #313131"};
`;

const DataCardAvatar = styled.div`
  width: 126px;
  height: 126px;
  border-radius: 100%;
  background: #232323;
`;

/**
 *NOTE: Common Component
 * @description This Component is about Data Source, which user will select while Account Setup.
 * @param {*} props
 * @return {*}
 */
const DataSourceCard = (props) => {
  const {
    handleSourceCardSelection,
    isSourceSelected,
    disabled,
    iconImages,
    name,
  } = props;

  return (
    <DataCardComponent
      disabled={disabled}
      isSourceSelected={isSourceSelected}
      onClick={handleSourceCardSelection}
    >
      <Col xs={12} className="dataCardGreenTickContainer">
        {isSourceSelected ? (
          <Col xs={1} className="dataCardGreenTick">
            <img src={TickIcon} />
          </Col>
        ) : null}
      </Col>

      <Col xs={12} className="dataCardMainIconContainer">
        <Row>
          <DataCardAvatar>
            <Col xs={12} className="dataCardAvatar">
              <img src={iconImages} width="56px" height="56px" alt="status" />
            </Col>
          </DataCardAvatar>
        </Row>
      </Col>

      <Col xs={12} className="dataCardText">
        <Typography text={name} tag={"p-bold-large"} color={"#FAFAFA"} />
      </Col>
    </DataCardComponent>
  );
};

export default DataSourceCard;
