import React from "react";

const PipelinesMainPage = () => {
  return (
    <div
      style={{
        backgroundColor: "#171717",
        height: "100vh",
        color: "white",
        fontSize: "20px",
      }}
    >
      .............PipelinesMainPage Content Under Development........
    </div>
  );
};

export default PipelinesMainPage;
