import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import LeftArray from "../../../src/assets/images/leftArrow.png";
import DemoIcon from "../../../src/assets/images/demo.jpeg";

const WorkSpaceDropDown = (props) => {
  const history = useHistory();
  const { workSpaces, handleWorkSpaceChange, closeDropdown } = props;
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [closeDropdown]);

  const handleWorkSpaceClick = (selectedWorkSpace) => {
    handleWorkSpaceChange(
      selectedWorkSpace.name,
      selectedWorkSpace.id,
      selectedWorkSpace.icon_link
    );
    closeDropdown(); // Call the closeDropdown function passed as prop to close the dropdown
  };

  const handleAllWorkSpaceClick = () => {
    history.push("/");
  };

  return (
    <div className="workSpaceDropDownContainer" ref={dropdownRef}>
      {workSpaces.slice(0, 4).map((eachData, index) => (
        <div
          className="workSpaceDropDownItem"
          key={eachData.id}
          onClick={() => handleWorkSpaceClick(eachData)}
        >
          <div className="navBarWorkspaceLogo">
            <img src={eachData.icon_link} alt="" />
          </div>
          <p className="navBarWorkspaceName">{eachData.name}</p>
        </div>
      ))}
      <div
        className="workSpaceDropDownButton"
        onClick={handleAllWorkSpaceClick}
      >
        <div className="workSpaceDropDownButtonContent">
          <p>View All Workspaces</p>
          <div className="workSpaceDropDownButtonImage">
            <img src={LeftArray} alt="leftArrow" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkSpaceDropDown;
