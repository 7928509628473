import moment from "moment";
import DemoIcon from "../../../src/assets/images/demo.jpeg";
import SettingsIcon from "../../../src/assets/images/Settings.svg";
import NotFound from "../../../src/assets/images/photo.png";
/**
 *NOTE: Common Component
 * @description This Component will show Users Profile and Account creation Date.
 * @param {*} props
 * @return {*}
 */
const ProfileCard = (props) => {
  let { eachWorkSpace } = props;

  const pipelineCounts = eachWorkSpace.layout?.node?.length ?? 0;

  const handleWorkSpaceClick = (
    workSpaceId,
    workSpaceName,
    workSpaceIconLink
  ) => {
    props.handleClickedWorkSpace(workSpaceId, workSpaceName, workSpaceIconLink);
  };

  return (
    <div
      className="profileCardMainContainer"
      onClick={() =>
        handleWorkSpaceClick(
          eachWorkSpace.id,
          eachWorkSpace.name,
          eachWorkSpace.icon_link
        )
      }
    >
      <div className="profileCardProfileArea">
        <div className="profileCardProfileIcon">
          <img
            src={eachWorkSpace.icon_link ? eachWorkSpace.icon_link : NotFound}
            alt=""
            height="32px"
          />
        </div>
        <p className="profileCardProfileText">{eachWorkSpace.name}</p>
      </div>

      <p className="profileCardDescription">{eachWorkSpace.description}</p>

      <p className="profileCardCreateDate">{`Created on ${moment
        .unix(eachWorkSpace.createdat)
        .format("DD MMM YY")}`}</p>

      <p className="profileCardPipelineCount">
        {`${pipelineCounts} 
        ${pipelineCounts < 2 ? "Pipeline" : "Pipelines"}`}
      </p>

      <div className="profileCardSettingsArea">
        <div className="profileCardSettingsIcon">
          <img
            src={SettingsIcon}
            alt=""
            style={{
              width: "100%",
              height: " 100%",
              objectFit: "contain",
            }}
          />
        </div>
        <p className="profileCardSettingsText">Workspace Settings</p>
      </div>
    </div>
  );
};

export default ProfileCard;
