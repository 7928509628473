import { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import Typography from "../common/_typography";
import DownIcon from "../../assets/images/expandDropDown.png";
import UpIcon from "../../assets/images/expandDropUp.png";
import BlackTickIcon from "../../assets/images/check_circle_black_24dp.svg";
import GreenTickIcon from "../../assets/images/check_circle_green_24dp.svg";

//TODO: Below title and list will come as props.
const title = "Pipeline Setup";
const list = [
  { id: 1, title: "Line No 1", status: true },
  { id: 2, title: "Lets Play A Game", status: true },
  { id: 3, title: "Hello Buddy", status: false },
  { id: 4, title: "Line No 1", status: true },
  { id: 5, title: "Hello Buddy", status: false },
  { id: 6, title: "Line No 1", status: true },
];

const ProgressBarStyledComponent = styled.div`
  margin: 10px 0px 16px 0px;
  height: 10px;
  background: rgba(142, 133, 255, 0.4);
`;

const ProgressBarSuccessStyledComponent = styled.div`
  width: 50px;
  height: 10px;
  background: #8e85ff;
`;

/**
 *NOTE: Common Component
 * @description This Component will show the progress while Pipeline Setup.
 * @param {*} props
 * @return {*}
 */
const ProgressBar = () => {
  const [viewDropDown, setViewDropDown] = useState(false);
  const [percentageValue, setPercentageValue] = useState(0);
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (list.length > 0) {
      calculatePercentage();
    } else {
      setPercentageValue(0);
    }
  }, []);

  const calculatePercentage = () => {
    let count = 0;
    for (let i in list) {
      if (list[i].status == true) {
        count++;
      }
    }

    let percentageOfOneItem = 100 / list.length;

    let totalPercentage = (percentageOfOneItem * count).toFixed(2);

    setPercentageValue(totalPercentage);

    setProgressValue((335 * totalPercentage) / 100);
  };

  const handleDropDownIconChange = () => {
    setViewDropDown(!viewDropDown);
  };

  return (
    <Container fluid>
      <Row>
        <Col
          xs={12}
          className={
            viewDropDown
              ? "progressBarMainColumnSecondary"
              : "progressBarMainColumnPrimary"
          }
        >
          <Col className="progressBarFirstColumn">
            <Row>
              <Col xs={8}>
                <Row>
                  <Col xs={9}>
                    <Typography
                      text={title}
                      color={"#FAFAFA"}
                      tag={"head-x-x-small"}
                    />
                  </Col>
                  <Col xs={3} className="progressBarDropDownIcon">
                    <img
                      onClick={handleDropDownIconChange}
                      src={viewDropDown ? UpIcon : DownIcon}
                      width="20.15px"
                      height="19.15px"
                    />
                  </Col>
                </Row>
              </Col>
              <Col xs={4} className="progressBarPercentage">
                <Typography
                  text={`${percentageValue} %`}
                  color={"#FAFAFA"}
                  tag={"head-x-x-small"}
                />
              </Col>
            </Row>
          </Col>

          <Col>
            <ProgressBarStyledComponent>
              <ProgressBarSuccessStyledComponent />
            </ProgressBarStyledComponent>
          </Col>

          <Col
            className={
              viewDropDown && list.length > 3
                ? "progressBarFlexScroll"
                : "progressBarContainerStyle"
            }
          >
            {viewDropDown && list.length > 0
              ? list.map((eachListItem) => (
                  <Row key={eachListItem.id} className="progressBarList">
                    <Col xs={10} className="progressBarListTitle">
                      <Typography
                        text={eachListItem.title}
                        color={"#FAFAFA"}
                        tag={"head-x-x-small"}
                      />
                    </Col>
                    <Col xs={2} className="progressBarListIcon">
                      <img
                        src={
                          eachListItem.status ? GreenTickIcon : BlackTickIcon
                        }
                        width="20.15px"
                        height="19.15px"
                      />
                    </Col>
                  </Row>
                ))
              : null}
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default ProgressBar;
