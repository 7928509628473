import { useState, useContext, useEffect } from "react";
import { Spinner } from "reactstrap";
import Typography from "../../components/common/_typography";
import BubbleLoader from "../../components/editorComponents/BubbleLoader";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import DemoIcon from "../../../src/assets/images/demo.jpeg";
import CloseIcon from "../../../src/assets/images/closeModal.png";
import TempoIcon from "../../../src/assets/images/task_alt_white.svg";
import { useTour } from "@reactour/tour";
import { workspace } from "../../steps";

const CommonOnboardingPrgBarTitle = (props) => {
  const { setIsOpen, currentStep, setSteps } = useTour();
  const { showMessage } = useContext(SnackbarContext);

  const { title, showButton, handleRefetchWorkSpace } = props;

  const [isModelOpen, setIsModalOpen] = useState(false);
  const [workSpaceName, setWorkSpaceName] = useState("");
  const [workSpaceDescription, setWorkSpaceDescription] = useState("");
  const [isWorkSpaceBeingCreated, setIsWorkSpaceBeingCreated] = useState(false);
  const [allIcons, setAllIcons] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState("");

  useEffect(() => {
    getAllIcons();
    setSteps(workspace);
  }, []);

  const getAllIcons = async () => {
    let getAllIconsResponse = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getAllIcons"
    );

    setAllIcons(getAllIconsResponse.data.data);
  };

  const handleCreateWorkSpace = async () => {
    if (
      workSpaceName != "" &&
      workSpaceDescription != "" &&
      selectedIcon != ""
    ) {
      setIsWorkSpaceBeingCreated(true);

      let stringifyData = {
        name: workSpaceName,
        description: workSpaceDescription,
        icon_file: selectedIcon,
      };

      let setWorkspaceList = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setWorkSpace",
        stringifyData
      );

      if (setWorkspaceList && setWorkspaceList.data.status == "CREATED") {
        setIsWorkSpaceBeingCreated(false);
        setWorkSpaceName("");
        setWorkSpaceDescription("");
        setIsModalOpen(!isModelOpen);

        showMessage("Workspace Created Successfully", "success");
        handleRefetchWorkSpace();
      } else {
        setIsWorkSpaceBeingCreated(false);
        setWorkSpaceName("");
        setWorkSpaceDescription("");
        setIsModalOpen(!isModelOpen);

        showMessage("Failed to create workspace");
        handleRefetchWorkSpace();
      }
      showMessage("Workspace Created Successfully", "success");
      handleRefetchWorkSpace();
    } else {
      showMessage("Workspace Name, Description and Icon are required.");
    }
  };

  useEffect(() => {
    if (currentStep === 3) {
      setIsModalOpen(!isModelOpen);
    }
  }, [currentStep]);

  const handleCreateWorkSpaceModal = () => {
    setIsModalOpen(!isModelOpen);
    setWorkSpaceName("");
    setWorkSpaceDescription("");
    setSelectedIcon("");
  };

  const handleIconClick = (icon) => {
    setSelectedIcon(icon);
  };

  return (
    <div className="onBoardingWokspaceTitle">
      <div xs={6} tour-guide="workspace_intro">
        <Typography text={title} tag={"head-small"} color={"#FAFAFA"} />
      </div>
      <div tour-guide="create_workspace" />

      {showButton ? (
        <div
          className="onBoardingWokspaceTitleButton"
          onClick={handleCreateWorkSpaceModal}
          tour-guide="new_workspace"
        >
          <p>New Workspace</p>
        </div>
      ) : null}
      {isModelOpen && (
        <dialog open className="createWorkSpaceModal">
          <div
            className="createWorkSpaceMainContainer"
            tour-guide="create_workspace_card"
          >
            <div className="createWorkSpaceContainerTitle">
              <p>Create a new Workspace</p>

              <div onClick={handleCreateWorkSpaceModal}>
                <img
                  src={CloseIcon}
                  alt=""
                  style={{
                    width: "100%",
                    height: " 100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
            <div className="createWorkSpaceContainerFormArea">
              <input
                type="text"
                placeholder="Workspace Name"
                value={workSpaceName}
                onChange={(e) => setWorkSpaceName(e.target.value)}
                className="createWorkSpaceContainerFormName"
              />

              <div className="createWorkSpaceContainerFormDescription">
                <textarea
                  type="text"
                  placeholder="Workspace Description"
                  value={workSpaceDescription}
                  onChange={(e) => setWorkSpaceDescription(e.target.value)}
                  className="createWorkSpaceContainerFormDescriptionInput"
                />
                <p className="createWorkSpaceCharacterCount">0/50</p>
              </div>

              <p className="createWorkSpaceContainerFormIconTitle">
                Choose Workspace Icon
              </p>

              <div className="createWorkSpaceContainerFormIcons">
                {allIcons.map((icon) => {
                  return (
                    <div
                      key={icon.id}
                      className="createWorkSpaceIcon"
                      onClick={() => handleIconClick(icon.icon_file)}
                      style={{
                        border:
                          icon.icon_file == selectedIcon
                            ? "2px solid white"
                            : null,
                      }}
                    >
                      <img
                        src={icon.icon_link}
                        alt={icon.name}
                        className="createWorkSpaceIconStyle"
                      />
                    </div>
                  );
                })}
              </div>

              {isWorkSpaceBeingCreated ? (
                <div className="createWorkSpaceSpinnerContainer">
                  <Spinner
                    color="light"
                    className="createWorkSpaceSpinnerStyle"
                  />
                </div>
              ) : (
                <>
                  <div className="createWorkSpaceFormSubmitContainer">
                    <button
                      className="createWorkSpaceFormSubmitButton"
                      onClick={handleCreateWorkSpace}
                    >
                      Create Workspace
                    </button>
                  </div>

                  <div className="createWorkSpaceFormCancelContainer">
                    <button
                      className="createWorkSpaceFormCancelButton"
                      onClick={handleCreateWorkSpaceModal}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </dialog>
      )}
    </div>
  );
};
export default CommonOnboardingPrgBarTitle;
