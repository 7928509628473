import React, { useState } from "react";
import CommonValidationStatus from "./CommonValidationStatus";
import CommonLandingPage from "./CommonLandingPage";
import ForgetPasswordOtpValidation from "./ForgetPasswordOtpValidation";

/**
 * NOTE: Structured Component
 * @description This Component is for entering OTP page, related to Forget Password.
 * @return {*}
 */
const ForgetPasswordOtpVerification = (props) => {
  const [isOtpValidated, setOptValidation] = useState(null);

  const otpHandler = (e) => {
    if (isOtpValidated != null) {
      setOptValidation(!setOptValidation);
    } else {
      setOptValidation(e);
    }
  };

  const handleContinueButtonClick = () => {};

  const handleTryAgainButtonClick = () => {};

  return (
    <CommonLandingPage>
      {isOtpValidated == null && (
        <ForgetPasswordOtpValidation
          handleClickHandler={otpHandler}
          title={"Forgot your Password?"}
          description={"Please verify the OTP sent to your email address"}
          placeholder={"Enter OTP Here"}
          userName={props.location.userName}
        />
      )}
      {isOtpValidated == true && (
        <CommonValidationStatus
          title={"Password reset details successfully sent"}
          description={
            "We have successfully sent an email with password reset instructions to your email. Please verify the OTP and change your password"
          }
          status={"success"}
          buttonPlaceholder={"Continue"}
          handleButtonClick={handleContinueButtonClick}
          helperTextRoute={"/login"}
          helperText={"Login"}
        />
      )}

      {isOtpValidated == false && (
        <CommonValidationStatus
          title={"Failed to send password reset details"}
          description={
            "There was a problem sending password reset details. Please try again."
          }
          status={"fail"}
          buttonPlaceholder={"Try Again"}
          handleButtonClick={handleTryAgainButtonClick}
          helperTextRoute={"/login"}
          helperText={"Login"}
        />
      )}
    </CommonLandingPage>
  );
};

export default ForgetPasswordOtpVerification;
