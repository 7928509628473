import { useEffect, useState } from "react";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { Handle, Position } from "reactflow";
import { useHistory } from "react-router-dom";
import antenna from "../../assets/images/antenna_icon.svg";
import SettingsIcon from "../../../src/assets/images/Settings.svg";
import GreenTickIcon from "../../assets/images/check_circle_green_24dp.svg";
import AddNode from "../../assets/images/stories.svg";
import WarningButton from "../../assets/images/warning_red_24dp.svg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const ConnectorsCard = (props) => {
  const history = useHistory();

  const { data } = props;

  const [allConfigIdsStatus, setAllConfigIdsStatus] = useState([]);
  // const [allConfigIdsStatus, setAllConfigIdsStatus] = useState([
  //   {
  //     id: 518,
  //     status: "RUNNING",
  //   },
  //   {
  //     id: 519,
  //     status: null,
  //   },
  // ]);

  useEffect(() => {
    getNodeStatusInterval();
  }, []);

  const getNodeStatusInterval = async () => {
    await fetchConfigIdsStatus();
    setInterval(() => {
      fetchConfigIdsStatus();
    }, 10000);
  };

  const fetchConfigIdsStatus = async () => {
    try {
      let stringifiedData = JSON.stringify({
        ids: props.data.allConfigIds,
      });

      let fetchConfigIdsStatusResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getNodeStatus",
        stringifiedData
      );

      setAllConfigIdsStatus(fetchConfigIdsStatusResponse.data.data);
    } catch (err) {
      console.log("err:", err);
    }
  };

  const getStatusForConfigId = (configId) => {
    const configStatus = allConfigIdsStatus.find(
      (item) => item.id === configId
    );

    return configStatus ? configStatus.status : null;
  };

  let currentStatusBackground;
  let currentStatusBorder;

  switch (data.status) {
    case "Stopped":
      currentStatusBackground = "#F69697";
      currentStatusBorder = "#FF2700";
      break;
    case "Halt":
      currentStatusBackground = "#FFF299";
      currentStatusBorder = "#CCB200";
      break;
    case "Running":
      currentStatusBackground = "#a3eac6";
      currentStatusBorder = "#27945e";
      break;
    default:
      break;
  }

  const handleAddClick = (nodeLabel) => {
    history.push({
      pathname: "/connectors",
    });
  };

  let gradientClassName;

  switch (data.title) {
    case "Input Data Source":
      gradientClassName = "pink-gradient";
      break;
    case "Output Data Source":
      gradientClassName = "orange-gradient";
      break;
    case "Transformer":
      gradientClassName = "yellow-gradient";
      break;
    default:
      break;
  }

  return (
    <div className="connectorCardMainContainer">
      {!data.firstNode && <Handle type="target" position={Position.Left} />}
      <div
        className={`connectorCardColoredContainer ${gradientClassName}`}
      ></div>
      <div className="connectorCardDetailsContainer">
        <div className="connectorCardDetailsRow1">
          <div className="connectorCardDetailsRow1Styling">
            <div className="connectorCardDetailsIconBox">
              <img
                src={antenna}
                alt=""
                className="connectorCardCommonIconStyle"
              />
            </div>
            <div className="connectorCardDetailsTitleText">{data.title}</div>
          </div>

          <div className="connectorCardDetailsProgressBox">
            <img
              src={
                getStatusForConfigId(data.configId) === "RUNNING"
                  ? GreenTickIcon
                  : WarningButton
              }
              alt=""
              className="connectorCardCommonIconStyle"
            />
          </div>
        </div>
        <div className="connectorCardDetailsRow2">{`Node: ${data.sourceName}`}</div>
        <div
          className="connectorCardDetailsRow3"
          data-tooltip-id="tooltip-example"
          data-tooltip-content={data.description}
        >
          {`Description: ${data.description}`}
          <Tooltip id="tooltip-example" effect="solid" place="bottom" />
        </div>
        <div className="connectorCardDetailsRow4">
          {`Connection Type: ${data.connectionType}`}
        </div>
        <div className="connectorCardDetailsRow5">
          <div className="connectorCardDetailsReConfigureIcon">
            <img
              src={SettingsIcon}
              alt=""
              className="connectorCardCommonIconStyle"
            />
          </div>
          <div className="connectorCardDetailsReConfigureText">
            Re-Configure
          </div>
        </div>
      </div>

      <Handle type="source" position={Position.Right} />

      {data.isNodeWithoutTarget && (
        <div className="connectorCardAddIconContainer">
          <img
            src={AddNode}
            alt="icon"
            className="connectorCardAddIcon"
            onClick={() => handleAddClick()}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectorsCard;
