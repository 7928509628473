import CommonOnboardingBackgroundPolka from "./CommonConenctorsBackgroundPolka";
import OnBoardingAddedConnectors from "./ConnectorsAddedComponents";
import { Col } from "reactstrap";

const ConnectorsMainPage = (props) => {
  let selectedWorkSpaceId = props.location.selectedWorkspace;
  return (
    <CommonOnboardingBackgroundPolka>
      <OnBoardingAddedConnectors selectedWorkSpaceId={selectedWorkSpaceId} />
    </CommonOnboardingBackgroundPolka>
  );
};

export default ConnectorsMainPage;
