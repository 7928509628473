import React from "react";
import { Col, Container, Row } from "reactstrap";
import Typography from "../../components/common/_typography";
import ConfigureCard from "../../components/onBoardingComponents/_configureCard";
import SetupCard from "../../components/onBoardingComponents/_setupCard";
import SkeletonLoading from "../../components/common/_skeletonLoading";

const ConfigureDevice = (props) => {
  const {
    deviceName,
    configs,
    handleConfigFieldsInputChange,
    inputValues,
    isConfigApisBeingCalled,
  } = props;

  return (
    <Container fluid>
      {/* <Row style={{ padding: "32px 0px 32px 0px" }}>
        <Col>
          <Typography
            text={"Configure the Device"}
            tag={"head-x-small"}
            color={"#FAFAFA"}
          />
        </Col>
      </Row> */}

      <Row>
        <Col xs={12} sm={6}>
          {isConfigApisBeingCalled ? (
            <SkeletonLoading height={"60vh"} />
          ) : (
            <ConfigureCard
              deviceName={deviceName}
              configs={configs}
              handleConfigFieldsInputChange={handleConfigFieldsInputChange}
              inputValues={inputValues}
            />
          )}
        </Col>
        <Col xs={12} sm={6}>
          <SetupCard />
        </Col>
      </Row>
    </Container>
  );
};

export default ConfigureDevice;
