import { useState } from "react";
import { Col } from "reactstrap";
import styled from "styled-components";
import greenTick from "../../assets/images/greenTick.png";

const Cardbackground = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 212px;
  height: 144px;
  border: ${(props) =>
    props.isCardSelected ? "4px solid #8E85FF" : "4px solid #313131"};
  border-radius: 16px;
`;

const CardInnerBackground = styled.div`
  position: absolute;
  margin-left: 8px;
  margin-top: 7px;
  width: 188px;
  height: 123px;
  background: ${(props) =>
    props.isCardSelected ? "rgba(142, 133, 255, 0.16)" : "#313131"};
  border-radius: 8px;
`;

const CommonManufacturerCards = (props) => {
  const [isCardSelected, setCardSelected] = useState(false);

  const cardClicker = () => {
    setCardSelected(!isCardSelected);
    let cardClickerHandler = props.onClick;
    cardClickerHandler();
  };

  return (
    <Col onClick={cardClicker}>
      <Cardbackground isCardSelected={isCardSelected}>
        <CardInnerBackground isCardSelected={isCardSelected}>
          <Col style={{ textAlign: "center", marginTop: "30px" }}>
            <img src={greenTick} alt="greenTick" height="50px" />
          </Col>
        </CardInnerBackground>
      </Cardbackground>
    </Col>
  );
};
export default CommonManufacturerCards;
