import InputField from "../../components/common/_loginInputField";
import Typography from "../../components/common/_typography";
import { Col, Row } from "reactstrap";

/**
 * NOTE: Common Component
 * @description This Component may contain two or more Input Field along with a button.
 * @param {*} props
 * @return {*} Callback Function for Input Field.
 */
const CommonMultiInputField = (props) => {
  return (
    <>
      <Col xs={12} className="landingTitle">
        <Typography text={props.title} color={" #FAFAFA;"} tag={"head-small"} />
      </Col>

      <Col xs={12} className="landingDescription">
        <Col>
          <Typography
            text={props.description}
            color={" #B7B7B7;"}
            tag={"head-x-x-small"}
          />
        </Col>
      </Col>

      <Col xs={12} className="inputTextStyle1">
        <InputField
          name="username"
          throwError={!props.isUsernameValid}
          placeholder={props.inputPlaceholder}
          type={props.inputType}
          value={props.username}
          onChange={props.handleUsernameChange}
        />
      </Col>

      <Col xs={12} className="inputTextStyle2">
        <InputField
          name="password"
          throwError={!props.isPasswordValid}
          placeholder={props.inputPlaceholder1}
          type={props.inputType1}
          value={props.password}
          onChange={props.handlePasswordChange}
        />
      </Col>
    </>
  );
};

export default CommonMultiInputField;
