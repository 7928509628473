import SupportIcon from "../../assets/images/Support.svg";

export const connectorsArr = [
  {
    id: 1,
    name: "Kinesis",
    type: "Telematics Device",
    description:
      "Stream Telematics data from a Teltonika FMB 120 device into Condense in Real-Time",
    logo: SupportIcon,
    isComingSoon: false,
    categoryName: "Input",
    subCategoryName: "InputOne",
  },
  {
    id: 2,
    name: "Event Hub",
    type: "Telematics Device",
    description:
      "Bharat 101 data from a Teltonika FMB 120 device into Condense in Real-Time",
    logo: SupportIcon,
    isComingSoon: false,
    categoryName: "Input",
    subCategoryName: "InputTwo",
  },
  {
    id: 3,
    name: "MQTT",
    type: "Telematics Device",
    description:
      "UX 101 data from a Teltonika FMB 120 device into Condense in Real-Time",
    logo: SupportIcon,
    isComingSoon: false,
    categoryName: "Transforms",
    subCategoryName: "TransformsOne",
  },
  {
    id: 4,
    name: "Kinesis",
    type: "Telematics Device",
    description:
      "GES 333 data from a Teltonika FMB 120 device into Condense in Real-Time",
    logo: SupportIcon,
    isComingSoon: false,
    categoryName: "Transforms",
    subCategoryName: "TransformsTwo",
  },

  {
    id: 5,
    name: "Kinesis",
    type: "Telematics Device",
    description:
      "Stream Telematics data from a Teltonika FMB 120 device into Condense in Real-Time",
    logo: SupportIcon,
    isComingSoon: false,
    categoryName: "Input",
    subCategoryName: "InputThree",
  },
  {
    id: 6,
    name: "Event Hub",
    type: "Telematics Device",
    description:
      "Bharat 101 data from a Teltonika FMB 120 device into Condense in Real-Time",
    logo: SupportIcon,
    isComingSoon: false,
    categoryName: "Output",
    subCategoryName: "OutputTwo",
  },
  {
    id: 7,
    name: "MQTT",
    type: "Telematics Device",
    description:
      "UX 101 data from a Teltonika FMB 120 device into Condense in Real-Time",
    logo: SupportIcon,
    isComingSoon: true,
    categoryName: "Output",
    subCategoryName: "OutputOne",
  },
  {
    id: 8,
    name: "Kinesis",
    type: "Telematics Device",
    description:
      "GES 333 data from a Teltonika FMB 120 device into Condense in Real-Time",
    logo: SupportIcon,
    isComingSoon: true,
    categoryName: "Output",
    subCategoryName: "OutputThree",
  },
];

export const categoriesArray = [
  {
    id: 1,
    title: "Input",
    color: "pink",
    isSelected: false,
  },
  {
    id: 2,
    title: "Transforms",
    color: "yellow",
    isSelected: false,
  },
  {
    id: 3,
    title: "Output",
    color: "orange",
    isSelected: false,
  },
];

export const subCategoriesArray = [
  {
    id: 1,
    title: "Input",
    color: "pink",
    lists: [
      {
        listId: 1,
        listTitle: "Select All Inputs",
        isChecked: false,
      },
    ],
  },
  {
    id: 2,
    title: "Transforms",
    color: "yellow",
    lists: [
      {
        listId: 1,
        listTitle: "Select All Transforms",
        isChecked: false,
      },
    ],
  },
  {
    id: 3,
    title: "Output",
    color: "orange",
    lists: [
      {
        listId: 1,
        listTitle: "Select All Outputs",
        isChecked: false,
      },
    ],
  },
];

export const transformDataArr = [
  {
    type: "text",
    name: "Input Parameter",
    label: "Input Parameter",
    objKey: "inputParameter",
    placeHolder: "Enter the name of the Input Stream",
    isRequired: true,
    description:
      "The Kinesis stream that you want to access. The Kinesis stream that you specify must already exist.",
  },
  {
    type: "text",
    name: "Threshold Value",
    label: "Threshold Value",
    objKey: "thresholdValue",
    placeHolder: "Enter the threshold value ",
    isRequired: true,
    description:
      "The Kinesis stream that you want to access. The Kinesis stream that you specify must already exist.",
  },
  {
    type: "text",
    name: "Buffer Value",
    label: "Buffer Value",
    objKey: "bufferValue",
    placeHolder: "Enter the Buffer value ",
    isRequired: true,
    description:
      "The Kinesis stream that you want to access. The Kinesis stream that you specify must already exist.",
  },
  {
    type: "text",
    name: "Threshold Direction",
    label: "Threshold direction",
    objKey: "thresholdDirection",
    placeHolder: "Choose the Threshold direction",
    isRequired: true,
    description:
      "The Kinesis stream that you want to access. The Kinesis stream that you specify must already exist.",
  },
  {
    type: "textarea",
    name: "Output configuration",
    label: "Output configuration",
    objKey: "outputConfiguration",
    placeHolder: "Write Output configuration",
    isRequired: true,
    description:
      "The Kinesis stream that you want to access. The Kinesis stream that you specify must already exist.",
  },
];
