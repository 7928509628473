import { Container, Col, Row } from "reactstrap";

const CommonOnboardingBackground = (props) => {
  return (
    <Container fluid className="onBoardingPageMainStyle">
      <>
        <Col xs={12}>{props.children}</Col>
      </>
    </Container>
  );
};

export default CommonOnboardingBackground;
