import styled from "styled-components";
import { Handle, Position } from "reactflow";
import { useHistory } from "react-router-dom";
import antenna from "../../assets/images/antenna_icon.svg";
import GreenTickIcon from "../../assets/images/check_circle_green_24dp.svg";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const ConnectorCardDashedSkeleton = styled.div`
  box-sizing: border-box;
  height: 120px;
  border: 2px dashed #909090;
`;
const ConnectorCardDashed = (props) => {
  const history = useHistory();

  const { data } = props;
  // console.log("data:", data);
  let currentStatusBackground;
  let currentStatusBorder;

  switch (data.status) {
    case "Stopped":
      currentStatusBackground = "#F69697";
      currentStatusBorder = "#FF2700";
      break;
    case "Halt":
      currentStatusBackground = "#FFF299";
      currentStatusBorder = "#CCB200";
      break;
    case "Running":
      currentStatusBackground = "#a3eac6";
      currentStatusBorder = "#27945e";
      break;
    default:
      break;
  }

  const handleAddClick = () => {
    history.push("/connectors");
  };

  let gradientClassName;

  switch (data.title) {
    case "Input Data Source":
      gradientClassName = "pink-gradient";
      break;
    case "Output Data Source":
      gradientClassName = "orange-gradient";
      break;
    case "Transformer":
      gradientClassName = "yellow-gradient";
      break;
    default:
      break;
  }

  return (
    <div className="connectorCardMainContainer">
      <div className="connectorCardMainContainerDashed">
        {!data.firstNode && <Handle type="target" position={Position.Left} />}
        <div
          className={`connectorCardColoredContainer ${gradientClassName}`}
        ></div>
        <div className="connectorCardDetailsContainer">
          <div className="connectorCardDetailsRow1">
            <div className="connectorCardDetailsRow1Styling">
              <div className="connectorCardDetailsIconBox">
                <img
                  src={antenna}
                  alt=""
                  className="connectorCardCommonIconStyle"
                />
              </div>
              <div className="connectorCardDetailsTitleText">{data.title}</div>
            </div>

            <div className="connectorCardDetailsProgressBox">
              <img
                src={GreenTickIcon}
                alt=""
                className="connectorCardCommonIconStyle"
              />
            </div>
          </div>
          <div className="connectorCardDetailsRow2">{`Node: ${data.sourceName}`}</div>
          <div
            className="connectorCardDetailsRow3"
            data-tooltip-id="tooltip-example"
            data-tooltip-content={data.description}
          >
            {`Description: ${data.description}`}
            <Tooltip id="tooltip-example" effect="solid" place="bottom" />
          </div>
          <div className="connectorCardDetailsRow4">
            {`Connection Type: ${data.connectionType}`}
          </div>
        </div>
      </div>
      <Handle type="source" position={Position.Right} />
    </div>
  );
};

export default ConnectorCardDashed;
