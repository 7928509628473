import { Col } from "reactstrap";
import hashTagIcon from "../../assets/images/hashtagIcon.png";
import Button from "../../components/common/_button";
import Typography from "../../components/common/_typography";

const OnboardingWelcome = (props) => {
  const handleConnectSourseButton = () => {
    let handleConnectSourceClick = props.onClickConnectSource;
    handleConnectSourceClick();
  };
  return (
    <Col className="onBoardingLandingPageStyle">
      <Col className="onBoardingHashTagStyle">
        <img src={hashTagIcon} alt="hashTagIcon" width="141px" />
      </Col>
      <Col className="onBoardingTitleStyle">
        <Typography
          text={"Welcome to your Workspace"}
          color={"#FAFAFA"}
          tag={"head-small"}
        />
      </Col>
      <Col className="onBoardingSubTitleStyle">
        <Typography
          text={
            "We have Pre-Configured a source block to directly get started Connect your Data Source to continue"
          }
          color={"#909090"}
          tag={"sub-head-small"}
        />
      </Col>
      <Col className="onBoardingButtonStyle">
        <Button
          tag={"primary"}
          btnText={"Connect Source"}
          onClickEvent={handleConnectSourseButton}
        />
      </Col>
    </Col>
  );
};

export default OnboardingWelcome;
