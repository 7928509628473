import React from "react";
import Terminal, { ColorMode } from "react-terminal-ui";
import { Col, Row } from "reactstrap";
import Typography from "../../components/common/_typography";
import { Button } from "reactstrap";

const TerminalConnector = (props) => {
  const { messages, statusMessage } = props;

  return (
    <Row>
      <Col xs={12} style={{ paddingTop: "30px", height: "300px" }}>
        <Row>
          <Col xs={12} style={{ padding: "15px" }}>
            <Button color="primary" disabled>
              <Typography
                key={statusMessage}
                text={[
                  <b style={{ color: "#8BF5FA" }}>Status : </b>,
                  statusMessage,
                ]}
                tag="p-large"
                color="#FAFAFA"
              />
            </Button>
          </Col>
        </Row>
        <Terminal
          prompt=">"
          colorMode={ColorMode.Dark}
          height="31rem"
          maxWidth="50%"
        >
          <Col xs={2} style={{ paddingTop: "50px" }}>
            {messages.map((e, index) => (
              <Col xs={4} style={{ paddingTop: "20px" }}>
                <Typography
                  key={index}
                  text={e}
                  tag="p-small"
                  color="#FAFAFA"
                />
              </Col>
            ))}
          </Col>
        </Terminal>
      </Col>
    </Row>
  );
};

export default TerminalConnector;
