import React from 'react'
import Connectors from "../../../src/assets/images/Connectors.svg";


const Stepper = ({step}) => {

    const isStepActive = (stepNumber) => step >= stepNumber;

    const arrowIcon = (
      <svg xmlns="http://www.w3.org/2000/svg" width="50" height="8.9" viewBox="0 0 50 8.9">
      <path fill="#FAFAFA" d="M50 4.2L45.8 0l-1.4 1.4L47 4.2H0v1.4h47L44.4 8.4l1.4.5 4.2-4.2z"/>
    </svg>
    );

    return (
        <div className="stepper-interface">
          {/* Step 1 */}
          <div className={`step ${isStepActive(1) ? "active" : "inactive"}`}>
            <div className="step-icon"><img src={Connectors} alt="Select Connector" /></div>
            <div className="step-text">Select Connector</div>
          </div>
          <div className="step-arrow">
          {arrowIcon}          
          </div>
    
          {/* Step 2 */}
          <div className={`step ${isStepActive(2) ? "active" : "inactive"}`}>
            <div className="step-icon"><img src={Connectors} alt="Select Connector" /></div>
            <div className="step-text">Configure Connector</div>
          </div>
          <div className="step-arrow">
          {arrowIcon} 
          </div>
    
          {/* Step 3 */}
          <div className={`step ${isStepActive(3) ? "active" : "inactive"}`}>
            <div className="step-icon"><img src={Connectors} alt="Select Connector" /></div>
            <div className="step-text">Deploy Connector</div>
          </div>
        </div>
      );
}

export default Stepper