export const workspace = [
  {
    selector: '[tour-guide="welome_workspace"]',
    content:
      "Welcome to Condense. You can customize your streaming data pipeline by adding your own devices and connectors.",
  },
  {
    selector: '[tour-guide="workspace_intro"]',
    content:
      "A workspace is an environment where users can develop, manage, and monitor various components, including pipelines, in real time.",
  },
  {
    selector: '[tour-guide="new_workspace"]',
    content:
      "You have the option of using the default workspace or creating your own.",
  },
  {
    selector: '[tour-guide="create_workspace"]',
    content: "Lets Create a Workspace...! ",
  },
  {
    selector: '[tour-guide="create_workspace_card"]',
    content: "Complete the details to start creating your own workspace...🎉",
  },
];

export const playground = [
  {
    selector: '[tour-guide="onBoarding_new"]',
    content: "Let's start a new connection....🤟🏼",
  },
  {
    selector: '[tour-guide="onBoarding_selector"]',
    content:
      "Select an input connector from the library to add it to your pipeline.",
  },
];

export const connectorsWalkthrough = [
  {
    selector: '[tour-guide="explain_connectors"]',
    content:
      "Select an input source from the library to add it to your pipeline.",
  },
];

export const configWalkthrough = [
  {
    selector: '[tour-guide="Configure_title"]',
    content:
      "Configure your connector with the essential information to receive data.",
  },
];

export const nodeMappingWalkthrough = [
  {
    selector: '[tour-guide="Configure_title"]',
    content:
      "Set up your connector in the pipeline to read data from your input data source and deploy it to your destination.",
  },
];

export const firstNodeSetup = [
  {
    selector: '[tour-guide="Configure_title"]',
    content:
      "Click on the node to view the logs of the streaming data, where you can use the terminal option.",
  },
  {
    selector: '[tour-guide="conclusion_end"]',
    content:
      "Hurray 🎉. You have completed the condense onboarding. Congratulation",
  },
];


// tour-guide="onBoarding_selector"
