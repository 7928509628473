import { Col, Row } from "reactstrap";
import AddNode from "../../assets/images/addNode.png";

const ConnectorsAddButton = (props) => {
  const handleTidyUp = props.handleTidyUp;

  return (
    <div className="tidyUpContainer" onClick={handleTidyUp}>
      <div className="tidyUpIcon">
        <img
          src={AddNode}
          alt=""
          style={{
            width: "100%",
            height: " 100%",
            objectFit: "contain",
          }}
        />
      </div>
      <div className="tidyUpText">Tidy Up</div>
    </div>
  );
};

export default ConnectorsAddButton;
