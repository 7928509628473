import { Col, Row } from "reactstrap";
import styled from "styled-components";
import GreenTickIcon from "../../assets/images/task_alt_white.svg";

const StyledRadioButton = styled.div`
  /* width: 408px; */
  @media (min-width: 460px) {
    width: 408px;
  }
  @media (max-width: 460px) {
    width: 240px;
  }
  @media (min-width: 460px) {
    height: 96px;
  }
  @media (max-width: 460px) {
    height: 132px;
  }
  box-sizing: border-box;
  cursor: pointer;
  border: 2px solid ${(props) => (props.isSelected ? "#47CCD6" : "#666666")};
  ${(props) => props.isSelected && "background: rgba(71, 204, 214, 0.16);"};
`;

const StyledRadioBtnTitle = styled.div`
  @media (min-width: 460px) {
    width: 305px;
    margin-top: 20px;
  }
  @media (max-width: 460px) {
    width: 220px;
    margin-top: 32px;
  }
  overflow-wrap: break-word;
  height: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: #fafafa;
`;

const StyledRadioBtnDescription = styled.div`
  margin-left: 16px;

  @media (min-width: 460px) {
    width: 305px;
    margin-top: 16px;
  }
  @media (max-width: 460px) {
    width: 200px;
    margin-top: 32px;
  }
  overflow-wrap: break-word;
  height: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #b7b7b7;
`;

const RadioButton = (props) => {
  const { isSelected, btnTitle, btnDescription } = props;

  const handleRadioButtonClick = () => {
    let parentClickElm = props.onClickEvent;
    parentClickElm();
  };

  return (
    <div onClick={() => handleRadioButtonClick()}>
      <StyledRadioButton isSelected={isSelected}>
        <Row>
          <Col xs="10">
            <StyledRadioBtnTitle>{btnTitle}</StyledRadioBtnTitle>
          </Col>
          {isSelected ? (
            <Col
              xs="2"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={GreenTickIcon} width="20px" height="20px" />
            </Col>
          ) : null}
        </Row>

        <StyledRadioBtnDescription>{btnDescription}</StyledRadioBtnDescription>
      </StyledRadioButton>
    </div>
  );
};

export default RadioButton;
