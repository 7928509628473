import { Container, Row } from "reactstrap";

const CommonConenctorsBackgroundPolka = (props) => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Container className="onBoardingPageMainStylePolka">
            {props.children}
          </Container>
        </Row>
      </Container>
    </div>
  );
};

export default CommonConenctorsBackgroundPolka;
