import React from "react";

const DropDownMenu = ({
  optionsStructure,
  handleNewDirectoryClicked,
  deleteFileOrFolder,
  handleRenameClicked,
}) => {
  const handleListClick = (id) => {
    if (id == 1) {
      handleNewDirectoryClicked("folder");
    } else if (id == 2) {
      handleNewDirectoryClicked("file");
    } else if (id == 3) {
      handleRenameClicked();
    } else if (id == 4) {
      deleteFileOrFolder();
    }
  };

  return (
    <div className="folderTreeMenuDrawer">
      {optionsStructure.map((option) => {
        return (
          <div
            key={option.id}
            onClick={() => handleListClick(option.id)}
            className="folderTreeMenuDrawerItem"
          >
            {option.text}
          </div>
        );
      })}
    </div>
  );
};

export default DropDownMenu;
