import React, { useState, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { Col } from "reactstrap";
import Typography from "../../components/common/_typography";
import InputField from "../../components/common/_loginInputField";
import ButtonLandingPage from "../LandingPages/CommonButtonLandingPage";
import CommonLandingPage from "./CommonLandingPage";
import CommonValidationStatus from "./CommonValidationStatus";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import axiosAdapter from "../../utils";
import { env } from "../../env";

/**
 * NOTE: Structured Component
 * @description This Component is for resetting password.
 * @return {*}
 */
const ResetPassword = (props) => {
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(null);

  const checkPassword = () => {
    const passwordRegex = new RegExp(
      /^(?=.*\d)(?=.*[A-Z])(?!.*[^a-zA-Z0-9!@#$%^&*_])(.{8,15})$/
    );

    if (passwordRegex.test(password) == false) {
      setIsPasswordValid(false);
      showMessage(
        "Password must have 8 to 15 aplhanumeric characters, one capital letter, special characters allowed are !@#$%^&*_"
      );
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async () => {
    let comparePassword;
    setIsPasswordValid(true);
    setIsPasswordMatch(true);

    //Input Fields Validation
    let checkPasswordResponse = await checkPassword();

    if (checkPasswordResponse == true) {
      //This comparePassword will return 0 (if passwords match), else -1 will be returned.
      comparePassword = password.localeCompare(confirmPassword);
      if (comparePassword == 0) {
        setIsPasswordMatch(true);
      } else {
        setIsPasswordMatch(false);
        showMessage("The passwords do not match.");
      }
    }

    if (checkPasswordResponse == true && comparePassword == 0) {
      try {
        let stringifiedData = JSON.stringify({
          clientId: env.REACT_APP_CLIENT_ID,
          otp: props.location.otp,
          userName: props.location.userName,
          password: password,
          emailId: "bohilan846@recutv.com",
        });

        let validateOtpResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/validateOtpAndSetCreds",
          stringifiedData
        );

        //API Call: Validating OTP and Resetting Password.

        if (validateOtpResponse.data.code == 200) {
          setIsPasswordResetSuccess(true);
        } else {
          setIsPasswordResetSuccess(false);
        }
      } catch (error) {
        setIsPasswordResetSuccess(false);
      }
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleLoginButtonClick = () => {
    history.push("login");
  };

  const handleTryAgainButtonClick = () => {
    history.push("forgetPassword");
  };

  return (
    <CommonLandingPage>
      {isPasswordResetSuccess == null ? (
        <div>
          <Col xs={12} className="landingTitle">
            <Typography
              text={"Reset your Password"}
              color={" #FAFAFA;"}
              tag={"head-small"}
            />
          </Col>
          <Col xs={12} className="landingDescription">
            <Col>
              <Typography
                text={
                  "Please enter a new password to continue accessing your Condense X account"
                }
                color={" #B7B7B7;"}
                tag={"head-x-x-small"}
              />
            </Col>
          </Col>

          <Col xs={12} className="inputTextStyle2">
            <InputField
              throwError={!isPasswordValid}
              name="password"
              placeholder="Enter Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </Col>

          <Col xs={12} className="inputTextStyle2">
            <InputField
              throwError={!isPasswordMatch}
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
          </Col>

          <Col xs={12} className="defaultLandingPageButtonStyle">
            <ButtonLandingPage
              disabled={password == "" || confirmPassword == ""}
              onClickEvent={() => handleSubmitClick()}
              buttonPlaceholder={"Reset Password"}
              linkLable={[
                <a href="/login">
                  <u>Login</u>
                </a>,
              ]}
            />
          </Col>
        </div>
      ) : null}

      {isPasswordResetSuccess == true && (
        <CommonValidationStatus
          title={"Password Successfully Created"}
          description={
            "We have successfully updated your password. Please login with the new password to continue using Condense X"
          }
          status={"success"}
          buttonPlaceholder={"Login"}
          handleButtonClick={handleLoginButtonClick}
          helperTextRoute={"/"}
          helperText={"Go To Homepage"}
        />
      )}

      {isPasswordResetSuccess == false && (
        <CommonValidationStatus
          title={"Problem Changing your Password"}
          description={
            "There was a problem changing your password. Please check the details, your internet connection and try again later."
          }
          status={"fail"}
          buttonPlaceholder={"Try Again"}
          handleButtonClick={handleTryAgainButtonClick}
          helperTextRoute={"/login"}
          helperText={"Login"}
        />
      )}
    </CommonLandingPage>
  );
};

export default withRouter(ResetPassword);
