import { Col, Row, Container } from "reactstrap";
import Typography from "../../components/common/_typography";
import DataSourceCard from "../../components/common/_dataSourceCard";
import TelematicIcon from "../../assets/images/TelematicDevice.svg";
import DataBaseIcon from "../../assets/images/DataBaseIcon.svg";
import ConnectorsIcon from "../../assets/images/ConnectorsIcon.svg";
import { playground } from "../../steps";
import { useTour } from "@reactour/tour";
import { useEffect } from "react";

const OnBoardingDataSourcePipelineSetup = (props) => {
  const { setIsOpen, currentStep, setSteps, steps, isOpen } = useTour();

  useEffect(() => {
    setSteps(playground);
    setIsOpen(true);
  }, []);

  const { handleSourceCardSelection, selectedSourceName } = props;
  return (
    <Row xs={12}>
      <Col>
        <Row>
          <Col xs={12} className="onBoardingPipeLineSubTitle">
            <div tour-guide="onBoarding_new">
              <Typography
                text={"Select the Source of your Data"}
                tag={"head-x-small"}
                color={"#FAFAFA"}
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="onBoardingPipeLineDescription">
            <Typography
              text={"Choose the data source that you wish to use "}
              tag={"p-large"}
              color={"#FAFAFA"}
            />
          </Col>
        </Row>

        <Row>
          <Col xs={12} className="onBoardingConnectorCardsStyle">
            <div
              tour-guide="onBoarding_selector"
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ marginRight: "24px" }}>
                <DataSourceCard
                  name={"Telematic Devices"}
                  disabled={false}
                  isSourceSelected={selectedSourceName == "Telematic Devices"}
                  handleSourceCardSelection={() =>
                    handleSourceCardSelection("Telematic Devices")
                  }
                  iconImages={TelematicIcon}
                />
              </div>

              <div style={{ marginRight: "24px" }}>
                <DataSourceCard
                  name={"Databases"}
                  disabled={false}
                  iconImages={DataBaseIcon}
                  isSourceSelected={selectedSourceName == "Databases"}
                  handleSourceCardSelection={() =>
                    handleSourceCardSelection("Databases")
                  }
                />
              </div>

              <div>
                <DataSourceCard
                  name={"Connectors"}
                  disabled={false}
                  iconImages={ConnectorsIcon}
                  isSourceSelected={selectedSourceName == "Connectors"}
                  handleSourceCardSelection={() =>
                    handleSourceCardSelection("Connectors")
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default OnBoardingDataSourcePipelineSetup;
