import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Controls,
  applyEdgeChanges,
  applyNodeChanges,
  MiniMap,
  Panel,
  ReactFlowProvider,
  useReactFlow,
} from "reactflow";
import { useHistory } from "react-router-dom";
import ConnectorsAddButton from "../../components/connectorsComponents/_connectorsAddButton";
import React, { useCallback, useState, useContext } from "react";
import dagre from "dagre";
import axiosAdapter from "../../utils";
import { Button, Col, Row } from "reactstrap";
import ConnectorsCard from "../../components/connectorsComponents/_connectorsCard";
import ConnectorCardDashed from "../../components/connectorsComponents/_connectorsCardDashed";
import "reactflow/dist/style.css";
import { useEffect } from "react";
import { env } from "../../env";
import closeModal from "../../assets/images/closeModal.png";
import cancelModal from "../../assets/images/cancel.png";
import MessageIcon from "../../../src/assets/images/demo.jpeg";
import ConnectorsAddedSource from "./ConnectorsAddedSource";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import Typography from "../../components/common/_typography";
import TerminalConnector from "./ConnectorsTerminal";
import StyledPauseButton from "../../components/common/_pauseButton";
import { io } from "socket.io-client";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const position = { x: 10, y: 10 };
const edgeType = "smoothstep";

let initialNodes = [];

let initialEdges = [];

let configIdArray = [];

const nodeWidth = 400;
const nodeHeight = 150;

const getLayoutedElements = (nodes, edges) => {
  if (nodes && edges) {
    // Find nodes that have no outgoing edges
    const nodesWithoutTarget = nodes
      .filter((node) => {
        return !edges.some((edge) => edge.source === node.id);
      })
      .map((node) => node.id);

    // console.log("nodesWithoutTarget:", nodesWithoutTarget);

    //Graph Related Logics
    dagreGraph.setGraph({ rankdir: "LR" });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node, i) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = "left";
      node.sourcePosition = "right";
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      if (node.position.x == 0 && node.position.y == 0) {
        node.position.x = 30;
        node.position.y = 30;
      } else if (node.position.x == 0) {
        node.position.x = 30;
      } else if (node.position.y == 0) {
        node.position.y = 30;
      }

      // Modifying data object of node
      // Check if the node's id is present in nodesWithoutTarget array
      if (nodes.length > 1) {
        node.data.isNodeWithoutTarget = nodesWithoutTarget.includes(node.id);
      } else {
        node.data.isNodeWithoutTarget = true;
      }

      //Inserting key "firstNode" = true, if node id is 1
      //This is being inserted only to hide , left side dot from node card from UI, if node card is first one
      if (node.id == 1) {
        node.data.firstNode = true;
      } else {
        node.data.firstNode = false;
      }

      //Picking all config Ids
      if (node.data?.configId) {
        configIdArray.push(node.data?.configId);
      }

      node.data.allConfigIds = configIdArray;

      return node;
    });

    return { nodes, edges };
  }
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  initialNodes,
  initialEdges
);

const nodeTypes = {
  customDashed: ConnectorCardDashed,
  custom: ConnectorsCard,
};

const nodeColor = (node, e) => {
  if (node?.data?.label) {
    switch (node.data.label) {
      case "input":
        return "#6ede87";
      case "output":
        return "#6865A5";
      default:
        return "#ff0072";
    }
  }
};

const Flow = (props) => {
  const { showMessage } = useContext(SnackbarContext);
  const { setViewport } = useReactFlow();
  let {
    setModalOpen,
    isModalOpen,
    isModalAddedConnectors,
    isModalTypePopover,
    isModalPopOver,
    setHandleTidyUp,
    disableTidyUp,
    setConfigIdForTerminal,
  } = props;
  const [nodes, setNodes] = useState(layoutedNodes);
  const [edges, setEdges] = useState(layoutedEdges);
  const [isSelectedNode, setSelectedNode] = useState(null);
  const [selectedNodeState, setSelectedNodeState] = useState(null);
  const [selectedWorkSpaceIconLink, setSelectedWorkSpaceIconLink] =
    useState("");

  const handleTidyUp = useCallback(async () => {
    let alignedWorkspaceList;

    if (
      props.workspaceLayout &&
      props.workspaceLayout.node &&
      props.workspaceLayout.edges
    ) {
      alignedWorkspaceList = getLayoutedElements(
        props.workspaceLayout.node,
        props.workspaceLayout.edges
      );
      if (
        alignedWorkspaceList &&
        alignedWorkspaceList.nodes &&
        alignedWorkspaceList.edges
      ) {
        setViewport({ x: 0, y: 0, zoom: 1 });
        setNodes(alignedWorkspaceList.nodes);
        setEdges(alignedWorkspaceList.edges);
      }
    }
  });

  useEffect(() => {
    let fetchWorkSpaceIdTemp = props.fetchWorkSpaceId;
    fetchWorkSpaceIdTemp(isSelectedNode, selectedNodeState);
  }, [isSelectedNode, selectedNodeState]);

  useEffect(() => {
    let alignedWorkspaceList;
    if (
      props.workspaceLayout &&
      props.workspaceLayout.node &&
      props.workspaceLayout.edges
    ) {
      alignedWorkspaceList = getLayoutedElements(
        props.workspaceLayout.node,
        props.workspaceLayout.edges
      );
      if (
        alignedWorkspaceList &&
        alignedWorkspaceList.nodes &&
        alignedWorkspaceList.edges
      ) {
        setNodes(alignedWorkspaceList.nodes);
        setEdges(alignedWorkspaceList.edges);
      }
    }

    //Setting SelectedWorkSpaceIconLink
    setSelectedWorkSpaceIconLink(
      localStorage.getItem("selectedWorkSpaceIconLink")
    );
  }, [props.workspaceLayout]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: ConnectionLineType.Bezier }, eds)
      ),
    []
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onNodeSelectedChange = (i, e) => {
    setConfigIdForTerminal(e.data.configId);
    setSelectedNode(e.id);
    setSelectedNodeState(e.data.isConnector);
    isModalTypePopover(true);
    if (e.data.isConnector === false) {
      setModalOpen(true);
    }
    if (e.data.isConnector === true) {
      if (isModalOpen) {
        showMessage("Please close the log stream");
      }
      if (!isModalOpen) {
        isModalAddedConnectors(true);
      }
    }
  };

  return (
    <>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodesConnectable={false}
        onNodeClick={onNodeSelectedChange}
        connectionLineType={ConnectionLineType.Bezier}
      >
        {/* <MiniMap nodeColor={nodeColor} /> */}
        <div className="reactFlowChatIcon">
          <img src={selectedWorkSpaceIconLink} alt="" height="48px" />
        </div>
        <Panel position="bottom-left">
          <ConnectorsAddButton handleTidyUp={handleTidyUp} />
        </Panel>
        {props.children}
      </ReactFlow>
    </>
  );
};

const ConnectorsAddedComponents = (props) => {
  const history = useHistory();

  const { showMessage } = useContext(SnackbarContext);
  const [workspaceLayout, setWorkspaceLayout] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalAddedConnectors, setModalAddedConnectors] = useState(false);
  const [isTerminalButtonClicked, setIsTerminalButtonClicked] = useState(false);
  const [isModalPopOver, setIsModalPopOver] = useState(false);
  const [selectedWorkSpace, setSelectWorkSpace] = useState(null);
  const [selectedWorkSpaceType, setSelectedWorkspaceType] = useState(null);
  const [nodeConfigIds, setNodeConfigIds] = useState(configIdArray);
  const [handleTidyUpState, setHandleTidyUp] = useState(false);
  const [configIdForTerminal, setConfigIdForTerminal] = useState(null);

  const [socket, setSocket] = useState(null);
  const [statusMessage, setStatusMessage] = useState("connecting");
  const [messages, setMessages] = useState([]);
  const [isTerminalResumed, setIsTerminalResumed] = useState(false);
  const [topicName, setTopicName] = useState("");

  const setNewTopicName = async () => {
    try {
      let stringifiedData = JSON.stringify({
        connectorId: configIdForTerminal,
      });

      let setNewTopicNameResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/getConfiguredConnector",
        stringifiedData
      );

      let data = JSON.parse(setNewTopicNameResponse.data.data.config);

      if (
        setNewTopicNameResponse.data.status == "OK" &&
        data.topic != undefined
      ) {
        setTopicName(data.topic);

        return { status: true, topicName: data.topic };
      } else if (
        setNewTopicNameResponse.data.status == "OK" &&
        data.topic === undefined
      ) {
        // setModalOpen(false);
        // showMessage("Topic Name not found");

        setTopicName("input");
        return { status: true, topicName: "input" };
      }
    } catch (err) {
      console.log("err:", err);

      return false;
    }
  };

  useEffect(() => {
    const connectToSocket = async () => {
      if (isTerminalButtonClicked) {
        const setNewTopicNameResponse = await setNewTopicName();

        if (setNewTopicNameResponse.status) {
          // const socket = io("http://localhost:3200", { path: "/logs" });
          const socket = io(env.REACT_APP_SOCKET_IO_URL, {
            path: "/logs",
          });

          setSocket(socket);
          socket.emit("startConsumer", {
            newEvent: setNewTopicNameResponse?.topicName,
          });

          socket.on("event", (message) => {
            setMessages((prevMessages) => [...prevMessages, message]);
          });
        } else {
          showMessage("Failed to fetch topic name");
        }
      }
    };

    connectToSocket();
  }, [isTerminalButtonClicked]);

  useEffect(() => {
    if (messages.length > 100) {
      setMessages((prevMessages) => prevMessages.slice(messages.length - 100));
    }

    if (!isTerminalResumed && messages.length) {
      setStatusMessage("connected");
    }
  }, [messages]);

  const handleStartButton = async () => {
    if (
      statusMessage === "connecting" ||
      statusMessage === "connected" ||
      statusMessage !== "paused"
    ) {
      console.log("Terminal already started");
      showMessage("Terminal already started");
    } else {
      await socket.emit("resumeConsumer", { newEvent: topicName });

      if (isTerminalResumed && messages.length < 101) {
        setStatusMessage("connecting");
        setIsTerminalResumed(false);
      }

      console.log("Terminal Reconnecting");
      showMessage("Terminal Reconnecting...");
    }
  };

  const handleStopButton = async () => {
    if (statusMessage === "paused") {
      console.log("Terminal already paused");
      showMessage("Terminal already paused");
    } else {
      await socket.emit("pauseConsumer", { newEvent: topicName });
      setIsTerminalResumed(true);
      setStatusMessage("paused");
      console.log("Terminal paused successfully");
      showMessage("Terminal paused successfully");
    }
  };

  async function fetchData() {
    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
    let stringifyData = { id: selectedWorkspaceId };
    let getWorkSpace = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace",
      stringifyData
    );

    if (
      getWorkSpace &&
      getWorkSpace.data &&
      getWorkSpace.data.data.layout != null
    ) {
      setWorkspaceLayout(getWorkSpace.data.data.layout);
      return true;
    } else {
      history.push({
        pathname: "onBoarding",
        onBoardingState: 3,
        selectedWorkspaceId: selectedWorkspaceId,
      });
    }
  }

  useEffect(() => {
    if (props.selectedWorkSpaceId) {
      localStorage.setItem("selectedWorkSpaceId", props.selectedWorkSpaceId);
    }
    fetchData();
  }, [props.selectedWorkSpaceId]);

  const setModalOpenFunction = (e) => {
    setModalOpen(e);

    if (e === false) {
      socket.emit("disconnectConsumer");
      setStatusMessage("connecting");
    }

    if (isTerminalButtonClicked) {
      setIsTerminalButtonClicked(false);
      setMessages([]);
      setStatusMessage("connecting");
    }
  };

  const isModalAddedConnectorsFunction = (e) => {
    setModalAddedConnectors(e);
    fetchData();
  };

  const isModalTypePopoverFunction = () => {
    setIsModalPopOver(true);
  };

  const handleRefetchNodes = async () => {
    let isFetchSuccess = await fetchData();
    if (isFetchSuccess) {
      showMessage("Deleted the node successfully", "success");
      setModalOpen(false);
      setIsModalPopOver(false);
    }
  };

  const setFetchWorkSpaceId = (e, i) => {
    // console.log("This is e, i", e, i);
    setSelectWorkSpace(e);
    setSelectedWorkspaceType(i);
  };

  const handleDeleteNode = async () => {
    let removeWorkspaceId;
    let removeConfigId;
    let selectedWorkspaceNodeList = [];
    let selectedWorkspaceEdgesList = [];
    let deleteableEdges = [];
    let tempWorkSpaceId = workspaceLayout;
    let selectedWorkSpaceTemp =
      selectedWorkSpace && JSON.parse(selectedWorkSpace);
    let nodeLength = tempWorkSpaceId.node.length;
    let edgeLength = tempWorkSpaceId.edges.length;
    if (selectedWorkSpaceTemp > 1 && selectedWorkSpaceType === false) {
      const sourceSet = new Set();

      console.log("selectedWorkSpaceTemp.....", selectedWorkSpaceTemp);

      for (let i = 0; i < edgeLength; i++) {
        sourceSet.add(tempWorkSpaceId.edges[i].source);
      }

      for (let i = 0; i < edgeLength; i++) {
        if (!sourceSet.has(tempWorkSpaceId.edges[i].target)) {
          deleteableEdges.push(tempWorkSpaceId.edges[i].target);
        }
      }

      for (let i = 0; i < nodeLength; i++) {
        if (
          selectedWorkSpaceTemp == tempWorkSpaceId.node[i].id &&
          deleteableEdges.includes(tempWorkSpaceId.node[i].id)
        ) {
          removeWorkspaceId = tempWorkSpaceId.node[i].id;
          removeConfigId = tempWorkSpaceId.node[i].data.configId;
        } else {
          selectedWorkspaceNodeList.push(tempWorkSpaceId.node[i]);
        }
      }

      if (removeWorkspaceId && removeConfigId) {
        for (let i = 0; i < edgeLength; i++) {
          let sourceValue =
            tempWorkSpaceId.edges[i].source &&
            JSON.parse(tempWorkSpaceId.edges[i].source);
          let targetValue =
            tempWorkSpaceId.edges[i].target &&
            JSON.parse(tempWorkSpaceId.edges[i].target);
          let tempSource = tempWorkSpaceId.edges[i];

          if (sourceValue < targetValue || !targetValue) {
            if (tempWorkSpaceId.edges[i].target != removeWorkspaceId) {
              selectedWorkspaceEdgesList.push(tempSource);
            }
          }
        }

        let nodeDeleteId = {
          connectorId: removeConfigId,
        };
        let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");

        let stringifyData = {
          id: selectedWorkspaceId,
          layout: {
            node: selectedWorkspaceNodeList,
            edges: selectedWorkspaceEdgesList,
          },
        };


        try {
          let deleteKubeApiNode = await axiosAdapter(
            "POST",
            env.REACT_APP_URL + "pipeline/deleteNamespaceDeployment",
            nodeDeleteId
          );
          console.log("deleteKubeApiNode......", deleteKubeApiNode.data);
        } catch (e) {
          console.error("Error: ", e);
        }

        let setWorkspaceList = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/setWorkSpace",
          stringifyData
        );

        if (setWorkspaceList && setWorkspaceList.data.status == "OK") {
          handleRefetchNodes();
        } else {
          showMessage("Failed to update the node deletion !");
        }
      } else {
        showMessage("Node deletion failed !");
      }
    } else {
      showMessage("Cannot Delete selected Node !");
      setModalOpen(false);
      setIsModalPopOver(false);
      setModalAddedConnectors(false);
    }

    console.log("selectedWorkspaceNodeList", selectedWorkspaceNodeList);
    console.log("selectedWorkspaceEdgesList", selectedWorkspaceEdgesList);
  };

  const handleTerminal = () => {
    setIsModalPopOver(false);
    setIsTerminalButtonClicked(true);
  };

  return (
    <Col xs={12} style={{ height: "calc(100vh - 80px)" }}>
      <ReactFlowProvider>
        <Flow
          workspaceLayout={workspaceLayout}
          setModalOpen={setModalOpenFunction}
          isModalAddedConnectors={isModalAddedConnectorsFunction}
          isModalTypePopover={isModalTypePopoverFunction}
          isModalOpen={isModalOpen}
          isModalPopOver={isModalPopOver}
          fetchWorkSpaceId={setFetchWorkSpaceId}
          setHandleTidyUp={handleTidyUpState}
          setConfigIdForTerminal={setConfigIdForTerminal}
        >
          {isModalPopOver && (
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "80vh",
                  zIndex: "900",
                  backdropFilter: "blur(5px)",
                }}
              >
                <Col
                  xs={6}
                  className="addedNewConnectors"
                  style={{ height: "120px", width: "600px" }}
                >
                  <Row>
                    <Col
                      xs={12}
                      style={{
                        padding: "10px 40px",
                        justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <div style={{ marginLeft: "100px" }}>
                        <Typography
                          text={"Please Choose to Continue"}
                          tag="sub-head-medium"
                          color="#FAFAFA"
                        />
                      </div>

                      <img
                        src={closeModal}
                        onClick={() => {
                          setIsModalPopOver(false);
                          setModalOpen(false);
                        }}
                        height="20px"
                        style={{
                          paddingTop: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </Col>

                    <Col
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Row>
                        <Col xs={6} style={{ padding: "10px" }}>
                          <Button
                            style={{
                              marginRight: "30px",
                            }}
                            size="lg"
                            color="danger"
                            outline
                            onClick={handleDeleteNode}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} style={{ padding: "10px" }}>
                          <Button
                            size="lg"
                            color="primary"
                            onClick={handleTerminal}
                          >
                            {selectedWorkSpaceType
                              ? "New Connector"
                              : "Terminal"}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          )}
          {isModalOpen && !isModalAddedConnectors && !isModalPopOver && (
            // TODO: Need to integrate Model for Log Streaming and Code editor
            <Row>
              <Col xs={8} className="connectorsPageModal">
                <Row>
                  <Col className="connectorsPageModalCloseBtn">
                    <img
                      src={closeModal}
                      onClick={() => setModalOpenFunction(false)}
                      height="30px"
                      style={{
                        paddingTop: "15px",
                        paddingRight: "15px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                  <Col xs={12} style={{ padding: "10px 30px" }}>
                    <Row>
                      <Col
                        xs={1}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Row>
                          <Col
                            className={
                              statusMessage !== "connected"
                                ? statusMessage == "connecting"
                                  ? "beaconIndicatorYellow"
                                  : "beaconIndicatorRed"
                                : "beaconIndicatorGreen"
                            }
                          />
                        </Row>
                      </Col>
                      <Col
                        xs={5}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          text="Input Data Source"
                          tag="head-x-small"
                          color="#FAFAFA"
                        />
                      </Col>
                      <Col
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <StyledPauseButton
                              onClick={handleStartButton}
                              iconType={"play"}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        xs={1}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Row>
                          <Col xs={12}>
                            <StyledPauseButton
                              onClick={handleStopButton}
                              iconType={"stop"}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <TerminalConnector
                      messages={messages}
                      statusMessage={
                        statusMessage === "connecting"
                          ? "Terminal connecting..."
                          : statusMessage === "connected"
                          ? "Terminal started"
                          : statusMessage === "paused"
                          ? "Terminal paused"
                          : ""
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}

          {isModalAddedConnectors && !isModalOpen && (
            <Row>
              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90vh",
                  zIndex: "3001",
                  backdropFilter: " blur(5px)",
                }}
              >
                <Col
                  xs={10}
                  className="addedNewConnectors"
                  style={{ height: "85vh", overflowY: "auto" }}
                >
                  <Row>
                    <Col xs={12} className="addedNewConnectorsCloseBtn">
                      <img
                        src={cancelModal}
                        onClick={() => setModalAddedConnectors(false)}
                        height="50px"
                        style={{
                          paddingTop: "15px",
                          paddingRight: "15px",
                          cursor: "pointer",
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ padding: "0px 40px" }}>
                      <ConnectorsAddedSource
                        workspaceLayout={workspaceLayout}
                        modalAddedConnectorsFunction={
                          isModalAddedConnectorsFunction
                        }
                        nodeConfigIds={nodeConfigIds}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          )}
        </Flow>
      </ReactFlowProvider>
    </Col>
  );
};

export default ConnectorsAddedComponents;
