import JSIcon from "../../assets/folderIcons/js.svg";
import CssIcon from "../../assets/folderIcons/css.svg";
import HtmlIcon from "../../assets/folderIcons/html.svg";
import JsonIcon from "../../assets/folderIcons/json.svg";
import JsxIcon from "../../assets/folderIcons/jsx.svg";
import DefaultIcon from "../../assets/folderIcons/eff.svg";

export const explorer = {
  id: 1680092544000,
  name: "root",
  isFolder: true,
  items: [
    {
      id: 1680092544001,
      name: "public",
      isFolder: true,
      items: [
        {
          id: 1680092544002,
          name: "public nested 1",
          isFolder: true,
          items: [
            {
              id: 1680092544003,
              name: "index.html",
              isFolder: false,
              code: `import React from "react";`,
              items: [],
            },
            {
              id: 1680092544004,
              name: "hello.css",
              isFolder: false,
              code: `import `,
              items: [],
            },
          ],
        },
        {
          id: 1680092544005,
          name: "public_nested_file.js",
          isFolder: false,
          code: `;`,
          items: [],
        },
      ],
    },
    {
      id: 1680092544006,
      name: "src",
      isFolder: true,

      items: [
        {
          id: 1680092544007,
          name: "App.jsx",
          isFolder: false,
          code: `"use 
	production: dbDetails
};`,
          items: [],
        },
        {
          id: 1680092544008,
          name: "Index.js",
          isFolder: false,
          code: `/
  );
}`,
          items: [],
        },
        {
          id: 1680092544009,
          name: "styles.css",
          isFolder: false,
          code: `/*
 
}
`,
          items: [],
        },
      ],
    },
    {
      id: 1680092544010,
      name: "package.json",
      isFolder: false,
      code: `,
      userId: DataTypes.INTEGER,
`,
      items: [],
    },
  ],
};

export const selectIcon = (iconName) => {
  switch (iconName) {
    case "js":
      return JSIcon;
      break;
    case "html":
      return HtmlIcon;
      break;
    case "css":
      return CssIcon;
      break;
    case "json":
      return JsonIcon;
      break;
    case "jsx":
      return JsxIcon;
      break;
    default:
      return DefaultIcon;
  }
};
